import React,{useEffect,useState,useMemo} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';
import { Dropdown, Modal,OverlayTrigger,Tooltip } from 'react-bootstrap';
import { ShortListDetailColumn } from './Columns';
import axios from 'axios';
import { NODE_API_URL,BASE_URL } from '../../../../../env_config';
import Cookies from "js-cookie";
import { CheckBox } from '../../../tableFilter';
import ExpandedDetais from './ExpandedDetails';
import { ConfirmGoods,HoldPopup,SwalWarn,SwalError } from '../../../Popup';
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaEllipsisV,FaChevronRight,FaChevronDown ,FaTrashAlt} from "react-icons/fa";
import {BsLightningFill } from "react-icons/bs";
import PerfectScrollbar from "react-perfect-scrollbar";
import SearchTableSkeleton from '../../../tables/SearchTableSkeleton';
import { useHistory } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import { TbTableOptions } from 'react-icons/tb';
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../_helpers';
import { ConfirmGoodOld } from '../../../Popup/ConfirmGoodOld';
import { HoldPopupOld } from '../../../Popup/HoldPopupOld';

const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

export const ShortListDetail = (props) => {
    const history = useHistory()
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const [data,setData] = useState([])
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [swalSingleWarnOpen,setSwalSingleWarnOpen] = useState(false)
    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [totalcarat,setTotalCarat] = useState(0)
    const [totalinrcar,setTotalInrCar] = useState(0)
    const [totalourprice,setTotalOurPrice] = useState(0)
    const [selecteddata,setSelectedData] = useState([])
    const [diamondselected,setDiamondSelected] = useState([])
    const [diamondcount,setDiamondcount] = useState(0)
    const [buypopup,setBuyPopUp] = useState(false)
    const [holdpopup,setHoldPopUp] = useState(false)
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
    const [buypopupctotal,setBuyPopUpTotal] = useState(0)
    const [singleDel,setSingleDel] = useState([])
    const [temp,setTemp] = useState(false)
    const [loader,setLoader] = useState(true)
    // console.log(props)
    const columns = useMemo(()=> ShortListDetailColumn(props.conversionrate,props.userinfo,props.symbol,GetRatio,props.language,history),[data,props.conversionrate,props.symbol] )

       useEffect(() => {
        axios({
            method: "POST",
            url: `${NODE_API_URL}/IVP/fetch-AllEnquiry`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            }
        }).then(res => {
            if(res && res.data && res.data.data){
                setData(res.data.data)
                setLoader(false)
            }else{
                setData([])
                setLoader(false)
            }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    },[props.userinfo.id,temp])

    const renderRowSubComponent = React.useCallback(
        (row) => (
            <>
                <ExpandedDetais row={row.row.original} conversionrate={props.conversionrate} symbol={props.symbol} language={props.language} totalinrcar={totalinrcar} totalourprice={totalourprice} diamondselected={diamondselected} buypopup={buypopup} BuyDiamond={BuyDiamond} HoldDiamond={HoldDiamond} symbolname={props.symbolname}/>
            </>
        ),[data]
      )

    const initialState = {
        sortBy: [
          {
            id: "Date",
            desc: true
          }
        ],
      };
      
    function GetRatio(row) {
        let $ratioval
        if (row.C_Shape !== 'ROUND') {
            if (row.C_Length >= row.C_Width) {
                $ratioval = (row.C_Length / row.C_Width).toFixed(2);
            } else if (row.C_Length < row.C_Width) {
                $ratioval = (row.C_Width / row.C_Length).toFixed(2);
            } else if (row.C_Shape === 'HEART') {
                $ratioval = (row.C_Length / row.C_Width).toFixed(2);
            } else {
                $ratioval = '-';
            }
        } else {
            $ratioval = '-';
        }
        return $ratioval
    }
    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }
    const handleClose = () => setCustomizeTable(false);
    
    function hasDuplicates(arr, key) {
        const uniqueKeys = new Set();
        for (const obj of arr) {
            const keyValue = obj.original[key];
            if (uniqueKeys.has(keyValue)) {
                return true; // Found a duplicate
            }
            uniqueKeys.add(keyValue);
        }
        return false; // No duplicates found
    }
    const BuyDiamond = (selectedFlatRows) => {
        if(selectedFlatRows.length === 0){
            alert("Please Select Diamonds")
            return
        }
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
        let inactive = false
        const selectedvalues = selectedFlatRows.map(value => {
            const finddata = data.find(val => value.original.StockId.toString() === val.StockId.toString())
            // if(finddata.country === "INDIA"){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.CalculatedPrice.total_our_price
                temptotalinrcar += (diamondcount>0?finddata.CalculatedPrice.T_C_Rate/diamondcount:0)
            // }
            if(finddata.stone_status === 0){
                inactive = true
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        let hasDuplicatesResult = hasDuplicates(selectedFlatRows, "StockId");
        if(hasDuplicatesResult){
            setSwalErrorOpen(true)
            setAlertMessage("You are trying to buy same stone, please choose another stone ...!!")
        }
        else if(inactive){
            // console.log(inactive,"inactive")
            setSwalErrorOpen(true)
            setAlertMessage("Please select only Active stone ...!!")
        }else{
            setDiamondSelected(selectedvalues)
            setDiamondcount(diamondcount)
            setBuyPopUp(true)
            setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
            setBuyPopUpTotal(temptotalourprice)
        }
    }
    
    const HoldDiamond = (selectedFlatRows) => {
    if(selectedFlatRows.length === 0){
        alert("Please Select Diamonds")
        return
    }
    let temptotalcarat = 0
    let temptotalinrcar = 0
    let temptotalourprice = 0
    let diamondcount = 0
    let inactive = false
    const selectedvalues = selectedFlatRows.map(value => {
        const finddata = data.find(val => value.original.StockId.toString() === val.StockId.toString())
        if(finddata.can_hold === 1){
            diamondcount += 1
            temptotalcarat += finddata.C_Weight
            temptotalourprice += finddata.CalculatedPrice.total_our_price
            temptotalinrcar += (diamondcount>0?finddata.CalculatedPrice.T_C_Rate/diamondcount:0)
        }
        if(finddata.stone_status === 0){
            inactive = true
        }
        return {
            ...finddata,
            temptotalcarat:temptotalcarat,
            temptotalinrcar:temptotalinrcar,
            temptotalourprice:temptotalourprice
        }
    })
    let hasDuplicatesResult = hasDuplicates(selectedFlatRows, "StockId");
    if(hasDuplicatesResult){
        setSwalErrorOpen(true)
        setAlertMessage("You are trying to buy same stone, please choose another stone...!!")
    }
    else if(inactive){
            // console.log(inactive,"inactive")
            setSwalErrorOpen(true)
            setAlertMessage("Please select only Active stone ...!!")
        }
        else{
            setDiamondSelected(selectedvalues)
            setHoldPopUp(true)
            setDiamondcount(diamondcount)
            setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
            setBuyPopUpTotal(temptotalourprice)
        }
    }
    const DeleteSwal = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        if(selectedFlatRows.length > 0){
            setAlertMessage("Are you sure want to Delete !!")
        } else{
            setAlertMessage("Please select record for delete !!")
        }
    } 
    const DeleteSingleSwal = (row) => {
        // setSemiAprroveModal(true)
        setSwalSingleWarnOpen(true)
        if (row && [row.original].length  !== 0){
            setSingleDel(row && [row.original].length !== 0 ? [row.original]:[])
            setAlertMessage("Are you sure want to Delete !!")
        } else {
            setAlertMessage("Please select record for delete !!")
        }
    } 
    const DeleteSelected = () => {
        if(selectedFlatRows){
            let Id = []
            selectedFlatRows.map(value => {
                Id.push(value.original.Id)
            })
            const userData = JSON.parse(localStorage.getItem("userData"))
            axios({
                method: "POST",
                url: `${NODE_API_URL}/IVP/delete-iFrame-Shortlist`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                data: {
                    Id:Id
                },
            }).then(res => {
                if(res && res.data){
                    setSwalWarnOpen(false)
                    setTimeout(() => { 
                        setTemp(!temp)
                    }, 500);
                    // window.location.reload(true)
                }
            }).
            catch(error => {
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
        }
    }
    const DeleteSingleSelected = () => {
        if(singleDel.length > 0){
           
            const userData = JSON.parse(localStorage.getItem("userData"))
            axios({
                method: "POST",
                url: `${NODE_API_URL}/IVP/deleteFrameShortlist`,
                headers: { "Content-Type": "application/json" },
                data: {
                    Id:[singleDel[0].Id],
                    "CustomerId":userData.CustomerId
                },
            }).then(res => {
                if(res && res.data){
                    setSwalSingleWarnOpen(false)
                    setTimeout(() => { 
                        setTemp(!temp)
                    }, 500);
                    // window.location.reload(true)
                }
            })
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        // setGlobalFilter,
        toggleAllRowsSelected,
        selectedFlatRows,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{
                // globalFilter,
                pageIndex,
                pageSize,
                // selectedRowIds
            },
    } = useTable({
        columns,
        data:data,
        initialState
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    {
                        id:'selection',
                        Header:({ getToggleAllPageRowsSelectedProps }) =>(
                            <CheckBox title="Select" {...getToggleAllPageRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => (
                            <div>
                              <CheckBox title="Select" {...row.getToggleRowSelectedProps()} />
                            </div>
                          ),
                    },
                    {
                    Header: () => null,
                    id: 'expander',
                    Cell: ({ row }) => (
                        <span  {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? <FaChevronDown className="text-success "  title="Collapse"/> :  <FaChevronRight className="text-primary" title="Expand" />}
                        </span>
                    ),
                    },
                    ...columns,
                    {
                        Header: props.language.CCMODE_SETUP_APPROVED_ACTION,
                        id: props.language.CCMODE_SETUP_APPROVED_ACTION,
                        Cell: ({ row }) => (
                            <>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Click for Delete
                                        </Tooltip>
                                    }
                                >
                                    <button className="btn btn-danger btn-sm" onClick={() => DeleteSingleSwal(row)}><FaTrashAlt /></button>
                                </OverlayTrigger>
                            </>
                        ),
                    },
                   
                ]
            })
        }
    )

    useEffect(() => {
        setSelectedData(selectedFlatRows)
    },[selectedFlatRows])
    useEffect(() => {
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        selecteddata.map(value => {
            const finddata = data.find(val => value.original.StockId.toString() === val.StockId.toString())
            if(finddata){
                temptotalcarat += finddata.C_Weight
                temptotalinrcar += (selecteddata.length>0?finddata.CalculatedPrice.T_C_Rate/selecteddata.length:0)
                temptotalourprice += finddata.CalculatedPrice.total_our_price
            }
        })
        setTotalCarat(temptotalcarat)
        setTotalInrCar(selecteddata.length>0?(temptotalourprice/temptotalcarat * props.conversionrate):0)
        setTotalOurPrice(temptotalourprice * props.conversionrate)
    },[selecteddata])
    const deselectAll = () => {
        toggleAllRowsSelected(false);
      };
      function numberWithCommas(x) {
        if (x) {
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return "0.00";
        }
    }
    return (
        <>
        <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
        <SwalError show={swalErrorOpen} message={alertMessage} onClose={() => setSwalErrorOpen(false)} responseOnOk={() => setSwalErrorOpen(false)}/> 
        <ConfirmGoodOld show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={props.symbol} conversionrate={props.conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={props.language}/>  
        <HoldPopupOld show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={props.symbol} conversionrate={props.conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount} language={props.language}/>  
        
        {loader? <SearchTableSkeleton /> :
        data.length > 0 ?
            <div className="card card-custom card-sticky gutter-b p-0 shadow-none">
                {selectedFlatRows.length>0?
                    <>
                        <div className="card-header custom-scrollbar flex-nowrap text-nowrap selecetedHeader" style={{minHeight:"50px"}}>
                            <div className="d-flex justify-content-center align-items-center natTab">
                                <button type="button" className="btn btn-primary mr-2 px-8 text-nowrap"  onClick={() => BuyDiamond(selectedFlatRows) }> <BsLightningFill className='font-size-h5' />{props.language.SEARCHLIST_BUYDIAMONDBTN}</button>
                                <button type="button" className="btn btn-hover-light-dark mr-1  text-nowrap"  onClick={() => HoldDiamond(selectedFlatRows) }><SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/hold.svg")} height="18px" width="18px" /> {props.language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                                <button type="button" className="btn btn-hover-light-dark mr-1 text-nowrap"  onClick={() => DeleteSwal()}><FaTrashAlt className='text-dark'/> Delete</button>
                            </div>
                            <div className="card-toolbar flex-nowrap">
                                <span className="selected-diamond-box mx-2">
                                    <span className='cursor-pointer' onClick={deselectAll}><IoMdClose className='text-dark'/></span> {selectedFlatRows.length} {props.language.DIAMOND_COMPARISION_SELDIAMONDS} | {props.language.SEARCHLIST_CARAT} : {numberWithCommas(parseFloat(totalcarat))} | Amount : {props.symbol}{numberWithCommas(parseFloat(totalourprice))}
                                </span>
                                <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> Customize Table</Tooltip> } >
                                    <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5" onClick={() => setCustomizeTable(true)}><TbTableOptions /></button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </>
                :
                    <div className="card-header p-0" style={{minHeight:"50px"}}>
                        <div className="card-title align-items-center">
                            <div className="d-flex align-items-center">
                                {/* <span className="cursor-pointer btn btn-light-warning btn-sm mr-2 " onClick={()=>props.setShortlistSwitchByMail(false)}><BsArrowLeftShort /> Back</span> */}
                                <span className="card-label font-weight-bolder text-dark"> Inquiry Details
                                </span>
                            </div>
                        </div>
                        <div className="card-toolbar flex-md-nowrap">
                            <OverlayTrigger placement="top" overlay={ <Tooltip id="tooltip"> Customize Table</Tooltip> } >
                                <button type="button" className="btn btn-hover-light-dark btn-icon mr-1 font-size-h5" onClick={() => setCustomizeTable(true)}><TbTableOptions /></button>
                            </OverlayTrigger>
                        </div>
                    </div>
                }
                <div className="card-body mt-3 p-0"> 
                        <div className="search-result table-responsive custom-scrollbar w-100">
                            <table className="table table-hover custom_datatable" {...getTableProps()}>
                                <thead className="custom_datatable_head" >
                                    {headerGroups.map(headerGroup =>(
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            { headerGroup.headers.map((column)=>(
                                                <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())} id={column.id}>
                                                    {column.render('Header')}
                                                    <span className="text-secondary">
                                                        {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {data.length > 0 && page.map((row,i) => {
                                        prepareRow(row)
                                        return(
                                            <React.Fragment key={i}>
                                                <tr {...row.getRowProps()} key={row.id}>
                                                    {row.cells.map((cell,i) => {
                                                        return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                    })}
                                                </tr>
                                                {row.isExpanded ? (
                                                    <tr>
                                                        <td className="p-0" colSpan={visibleColumns.length}>
                                                            {renderRowSubComponent({ row })} 
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </React.Fragment>
                                        )
                                    })|| <tr><td className="text-center bg-gray-200" colSpan="100%">No Data Available in Table</td></tr>}
                                </tbody>
                            </table>
                        </div> 
                </div>
                <div className="card-footer border-0">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="table_page_length">Page{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                        <div>
                            <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                            <span className="font-weight-bold text-muted">{'  '}
                                <input
                                    className="form-control d-inline-block form-control-sm"
                                    type="number"
                                    value={pageIndex + 1}
                                    // defaultValue={pageIndex + 1}
                                    onChange={ e => { 
                                        const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(pageNumber)
                                    }}
                                    style={{maxWidth:'70px'}}
                                />
                                {'  '}of{'  '} {pageOptions.length}{'  '}
                            </span>
                            <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                            <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                        </div>
                        <span className="d-flex align-items-center">
                        <span className="text-nowrap page_size">Rows Per page:{' '}</span>
                            <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                {
                                    [10, 25, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>{pageSize}</option>
                                    ))
                                }
                            </select>
                        </span>
                    </div>
                </div>
            </div> :  <div className="d-flex align-items-center justify-content-center"style={{minHeight:"50vh"}}>No Records found</div>
        }
            <SwalWarn show={swalWarnOpen} message={alertMessage} onHide={selectedFlatRows.length > 0 ?  () => setSwalWarnOpen(false) : ""} onClose={() => setSwalWarnOpen(false)} responseOnOk={selectedFlatRows.length > 0 ? () => DeleteSelected(): ""}/> 
            <SwalWarn show={swalSingleWarnOpen} message={alertMessage} onHide={singleDel.length > 0 ?  () => setSwalSingleWarnOpen(false) : ""} onClose={() => setSwalSingleWarnOpen(false)} responseOnOk={singleDel.length > 0 ? () => DeleteSingleSelected(): ""}/> 
            <Modal
                size="sm"
                show={CustomizeTable}
                onHide={() => setCustomizeTable(false)}
                aria-labelledby="Customize-table"
            >
                <Modal.Header className="px-5 py-3" closeButton>
                    <Modal.Title id="Customize-table">
                        Customize Columns
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-2 searchResult">
                    <PerfectScrollbar
                        options={perfectScrollbarOptions}
                        className="scroll"
                        style={{ maxHeight: "400px", position: "relative" }}
                    >
                    {/* <div  className="" > */}
                        <div>
                            <label className="checkbox my-2 border p-2">
                                <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                <span className="mr-2"></span>
                                Show All
                            </label> 
                        </div>
                        {
                            allColumns.map(column =>(
                                <div key={column.id} className={`${column.id}`}>
                                    <label className="checkbox my-2 border p-2">
                                        <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                        <span className="mr-2"></span>
                                        {column.Header}
                                    </label>
                                </div>
                            ))
                        }
                    {/* </div> */}
                    </PerfectScrollbar>
                </Modal.Body>
                <Modal.Footer className="px-5 py-3">
                    {/* <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={handleClose}>Close</button> */}
                    <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}